function SettingCrmPage(props) {
  const { lang, toast, business_data, ChangeDefaultGate } = props;

  const [gateList, setGateList] = React.useState([]);
  const [defaultGate, setDefaultGate] = React.useState("");
  const [tempDefaultGate, setTempDefaultGate] = React.useState("");
  const [searchTextLabel, setSearchTextLabel] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    getLeadTarget();
  }, []);

  const getLeadTarget = () => {
    axios({
      method: "post",
      url: backend + "lead" + "/lead-targets",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + business_data.token,
      },
      data: JSON.stringify({
        version: main.version,
        appId: business_data.app_id,
      }),
    }).then((res) => {
      if (res.data.result === 0) {
        let setGate =
          res.data.data.lead_default_gate_id &&
          res.data.data.lead_default_gate_id !== ""
            ? Number(res.data.data.lead_default_gate_id)
            : _.find(res.data.data.gates, { gate_name: "default" }).gate_id;
        setGateList(res.data.data.gates);
        setDefaultGate(setGate);
        setTempDefaultGate(setGate);
        setLoading(false);
      }
    });
  };

  const updateLeadDefaultGate = (gate_id) => {
    axios({
      method: "put",
      url:
        backend +
        "lead" +
        "/" +
        business_data.uid +
        "/" +
        business_data.pid +
        "/update-lead-default-gate",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "X-VC-Access-Token": business_data.access,
        Authorization: "Bearer " + business_data.token,
      },
      data: JSON.stringify({
        appId: business_data.app_id,
        gateId: gate_id,
      }),
    }).then((res) => {
      if (res.data.result === 0) {
        setTempDefaultGate(gate_id);
        toast.success(lang.__save_successfully);
      } else {
        toast.error(lang.__save_unsuccessfully);
      }
    });
  };

  const filteredGates =
    gateList &&
    gateList
      .filter((gate) => {
        return (
          gate.gate_name
            .toLowerCase()
            .includes(searchTextLabel.toLowerCase()) ||
          gate.gate_id.toString().includes(searchTextLabel)
        );
      })
      .sort((a, b) => a.gate_name.localeCompare(b.gate_name));

  const settingDefaultGate = (gate_id) => {
    setDefaultGate(gate_id);
  };

  const save = () => {
    updateLeadDefaultGate(defaultGate);
    ChangeDefaultGate(defaultGate);
  };

  const cancel = () => {
    setDefaultGate(tempDefaultGate);
  };

  return (
    <div style={{ height: "100%" }}>
      {loading ? (
        <div class="sp-placeholder">
          <div class="sp-text-help">
            <i class="icon icon-circle-notch animate-spin"></i>{" "}
            <span>{lang.__loading}</span>
          </div>
        </div>
      ) : (
        <>
          <div
            class="sp-row"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "60px",
              padding: "0 20px",
              borderBottom: "1px solid rgb(225, 231, 242)",
            }}
          >
            <div class="sp-col">
              <div class="sp-label">
                {lang.__r_crm}
              </div>
            </div>
          </div>
          <div
            style={{
              height: "calc(100% - 60px)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Detail
              style={{
                "flex-grow": "1",
              }}
            >
              <div
                style={{
                  marginTop: 20,
                }}
              >
                <div>{lang.__gate_for_create_lead}</div>
                <div
                  class="dropdown sp-dropdown sp-search"
                  style={{ minWidth: 287, width: 387, marginTop: 4 }}
                >
                  <div
                    class="dropdown-button dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    <div class="text" style={{ marginLeft: 10 }}>
                      <div class="sp-text -ellipsis">
                        {_.find(gateList, { gate_id: defaultGate }) &&
                          _.find(gateList, { gate_id: defaultGate }).gate_name}
                      </div>
                      <div class="sp-text-help" style={{ fontSize: "smaller" }}>
                        {_.find(gateList, { gate_id: defaultGate }) &&
                          _.find(gateList, { gate_id: defaultGate }).gate_id}
                      </div>
                    </div>
                    <i class="icon icon-angle-down"></i>
                  </div>
                  <div class="dropdown-menu sp-list">
                    <div class="sp-search" style={{ top: 0, position: "sticky", zIndex: 20, backgroundColor: "white" }}>
                      <i
                        class="icon icon-search"
                        style={{ marginLeft: 10 }}
                      ></i>
                      <input
                        type="text"
                        class="sp-input -borderless"
                        placeholder={lang.__search}
                        value={searchTextLabel}
                        onChange={(e) => setSearchTextLabel(e.target.value)}
                      />
                    </div>
                    {filteredGates.map((item) => {
                      return (
                        <div
                          class="item -clickable sp-row -gap"
                          style={{
                            backgroundColor:
                              item.gate_id === defaultGate ? "#6DA7FD" : "",
                            color: item.gate_id === defaultGate ? "white" : "",
                          }}
                          onClick={() => settingDefaultGate(item.gate_id)}
                          key={item.gate_id}
                        >
                          <div class="sp-col" style={{ marginLeft: 24 }}>
                            <div class="sp-text">{item.gate_name}</div>
                            <div class="sp-text-help">{item.gate_id}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Detail>

            <Footer>
              <button class="sp-btn -link" onClick={() => cancel()}>
                {lang.__cancel}
              </button>
              <button
                class="sp-btn -blue"
                onClick={() => save()}
                disabled={loading}
              >
                {loading && <i class="icon-circle-notch animate-spin"></i>}
                <span>{lang.__save}</span>
              </button>
            </Footer>
          </div>
        </>
      )}
    </div>
  );
}
